<template>
  <div class="container-fluid">
    <mdb-card cascade>
      <mdb-view
        class="card-header-reversed pl-2 pt-1 pb-1 d-flex justify-content-between align-items-center"
        cascade
      >
        <h3
          class="title-card-tec card-header-title color-primary-reversed"
          style="font-weight: 400"
        >
          Entreprises
        </h3>
        <mdb-btn
          class="float-right"
          tag="a"
          color="primary"
          icon="plus"
          size="sm"
          small
          @click="addOrEdit(null)"
        >
        </mdb-btn>
      </mdb-view>

      <mdb-card-body class="card-reversed" cascade v-loading="loading">
        <mdb-row>
          <mdb-col sm="12" md="4" class="mb-n4">
            <mdb-select
              flipOnScroll
              label="Nombre par page"
              outline
              @getValue="nbrPerPageSelected"
              v-model="dataPagination.pagination.perPageOptions"
            />
          </mdb-col>
          <mdb-col sm="12" md="4" class="mb-n4"> </mdb-col>
          <mdb-col sm="12" md="4" class="mb-n3">
            <mdb-input
              inputClass="border-reversed color-normal-reversed"
              labelClass=" color-normal-reversed"
              icon="search"
              outline
              type="text"
              v-model="dataPagination.searchQuery"
              placeholder="Rechercher"
              aria-label="Search"
            />
          </mdb-col>
        </mdb-row>

        <mdb-tbl responsiveMd >
          <mdb-tbl-head>
            <tr class="color-normal-reversed">
              <th v-for="column in data.columns" :key="column.name">
                {{ column.label }}
              </th>
            </tr>
          </mdb-tbl-head>
          <mdb-tbl-body>
            <tr
              class="tec-table-row color-normal-reversed"
              v-for="row in queriedData"
              :key="row.ifu"
            >
              <th class="tec-table-cell" scope="row">
                <span
                  class="badge rounded-pill bg-success"
                  style="height: 10px; width: 10px; display: inline-block"
                ></span>
                {{ row.name }}
              </th>
              <td class="tec-table-cell">{{ row.ifu }}</td>
              <td class="tec-table-cell">{{ row.rccm }}</td>
              <td class="tec-table-cell">{{ row.email }}</td>
              <td class="tec-table-cell">{{ row.phone }}</td>
              <td class="tec-table-cell">{{ row.address }}</td>
              <td class="d-flex justify-content-end">
                <el-dropdown trigger="click">
                  <span
                    class="el-dropdown-link"
                    style="cursor: pointer; font-weight: 500"
                  >
                    Actions<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu class="dropdown-el-tec" slot="dropdown">
                    <el-dropdown-item
                      @click.native="details(row)"
                      class="dropdown-item-el-tec"
                      icon="el-icon-notebook-2"
                      >Détails</el-dropdown-item
                    >
                    <el-dropdown-item
                      @click.native="addOrEdit(row)"
                      class="dropdown-item-el-tec"
                      icon="el-icon-edit"
                      >Modifier</el-dropdown-item
                    >
                    <el-dropdown-item
                      @click.native="deleteCustomer(row.id)"
                      class="dropdown-item-el-tec"
                      icon="el-icon-delete"
                      >Supprimer</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </td>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
        <div v-if="empty">
          <div
            class="d-flex justify-content-around align-items-center color-normal-reversed"
            style="padding: 50px"
          >
            <img
              :src="require('@/assets/svg/nodata.svg')"
              alt=""
              class="d-none flex-md-wrap d-md-block"
              style="width: 25%"
              srcset=""
            />
            <div>
              <h5>Commencez dès maintenant à ajouter vos premières entreprises !</h5>
              <p>
                Pour ajouter une nouvelle entreprise, cliquez sur le bouton
                <mdb-btn
                  class="z-depth-0"
                  style="cursor: inherit"
                  tag="a"
                  color="primary"
                  icon="plus"
                  size="sm"
                  small
                ></mdb-btn>
                en haut à votre droite.
              </p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <tec-pagination
            v-model="dataPagination.pagination.currentPage"
            :per-page="parseFloat(dataPagination.pagination.perPage)"
            :total="dataPagination.pagination.total"
          ></tec-pagination>
        </div>
      </mdb-card-body>
    </mdb-card>

    <mdb-modal
      centered
      size="lg"
      direction="top"
      position="top"
      :show="modalCompany.show"
    >
      <form @submit.prevent="modalCompany.edit ? update() : save()">
        <mdb-modal-body class="modal-tec card-reversed">
          <div class="modal-times-tec" @click="modalCompany.show = false">
            <i class="fas fa-times"></i>
          </div>
          <mdb-modal-title
            class="color-primary-reversed"
            style="font-weight: 500 !important; font-size: 1.5rem !important"
            >{{ modalCompany.title }}</mdb-modal-title
          ><br />
          <fieldset>
            <legend>Généralité</legend>
            <mdb-row>
              <mdb-col sm="12" md="12">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  :validation="$store.state.company.errors.name ? true : false"
                  :invalidFeedback="$store.state.company.errors.name"
                  v-model="form.name"
                  outline
                  label="Raison Sociale"
                  size="lg"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  :validation="$store.state.company.errors.ifu ? true : false"
                  :invalidFeedback="$store.state.company.errors.ifu"
                  type="number"
                  :min="0"
                  outline
                  label="IFU"
                  v-model="form.ifu"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  :validation="$store.state.company.errors.rccm ? true : false"
                  :invalidFeedback="$store.state.company.errors.rccm"
                  outline
                  label="RCCM"
                  v-model="form.rccm"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  outline
                  v-mask="'(###) ## ### ###'"
                  placeholder="(000)00 000 000"
                  label="Téléphone"
                  v-model="form.phone"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  outline
                  label="Adresse Email"
                  v-model="form.email"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="12">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  outline
                  label="Adresse Physique"
                  v-model="form.address"
                  size="lg"
                ></mdb-input>
              </mdb-col>
            </mdb-row>
          </fieldset>
          <fieldset v-if="!modalCompany.edit">
            <legend>Administrateur</legend>
            <mdb-row>
              <mdb-col sm="12" md="6">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  v-model="form.user.firstname"
                  outline
                  label="Prénoms"
                  size="md"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  outline
                  label="Nom"
                  v-model="form.user.lastname"
                  size="md"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  outline
                  label="Téléphone"
                  v-mask="'(###) ## ### ###'"
                  placeholder="(000)00 000 000"
                  v-model="form.user.phone"
                  size="md"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  outline
                  type="email"
                  label="Adresse Email"
                  size="md"
                  v-model="form.user.email"
                ></mdb-input>
              </mdb-col>
            </mdb-row>
          </fieldset>
          <fieldset v-if="!modalCompany.edit">
            <legend>Option Avancée</legend>
            <mdb-row>
              <mdb-col sm="12" md="6">
                <mdb-select
                  flipOnScroll
                  @getValue="getRegime"
                  v-model="regimeOption"
                  outline
                  label="Régime Fiscale"
                ></mdb-select>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  v-model="form.setting.nim"
                  outline
                  label="NIM"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  v-model="form.setting.signatory"
                  outline
                  label="Signataire"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  v-model="form.setting.signatory_title"
                  outline
                  label="Titre Signataire"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="12">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  v-model="form.setting.bank"
                  outline
                  label="Banque"
                  size="lg"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  v-model="form.setting.iban"
                  outline
                  label="IBAN"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  v-model="form.setting.sift"
                  outline
                  label="SWIFT"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="12">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  size="lg"
                  v-model="form.setting.bank_address"
                  outline
                  label="Adresse de la banque"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="6" class="mt-4">
                <label for=""> Afficher les informations bancaires sur la facture?</label>
                <mdb-switch
                  v-model="form.setting.bank_is_active"
                  offLabel="Non"
                  onLabel="Oui"
                />
              </mdb-col>
              <mdb-col sm="12" md="6" class="mt-4">
                <label for="">Numérotation des factures?</label>
                <mdb-switch
                  v-model="form.setting.is_automatic"
                  offLabel="Désactivé"
                  onLabel="Activé"
                />
              </mdb-col>
            </mdb-row>
          </fieldset>
          <div class="d-flex justify-content-end mt-5">
            <mdb-btn size="md" color="grey" @click="modalCompany.show = false"
              >Fermer</mdb-btn
            >
            <mdb-btn size="md" color="success" type="submit" :disabled="modalCompany.btn">
              <span v-if="!modalCompany.btn">Sauvegarder</span>
              <span
                v-if="modalCompany.btn"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span v-if="modalCompany.btn" class="pl-2">Loading...</span>
            </mdb-btn>
          </div>
        </mdb-modal-body>
      </form>
    </mdb-modal>

    <mdb-modal
      centered
      :show="modalCompanyDetails.show"
      @click="modalCompanyDetails.show = false"
    >
      <mdb-modal-body class="modal-tec px-0">
        <div class="modal-times-tec" @click="modalCompanyDetails.show = false">
          <i class="fas fa-times"></i>
        </div>
        <mdb-modal-title style="font-weight: 700" class="pl-2">
          {{ modalCompanyDetails.title }}
        </mdb-modal-title>

        <br />

        <table class="modal-details">
          <tbody>
            <tr>
              <th>Entreprise</th>
              <td>{{ modalCompanyDetails.content.name }}</td>
            </tr>
            <tr>
              <th>IFU</th>
              <td>{{ modalCompanyDetails.content.ifu }}</td>
            </tr>
            <tr>
              <th>RCCM</th>
              <td>{{ modalCompanyDetails.content.rccm }}</td>
            </tr>
            <tr>
              <th>Téléphone</th>
              <td>{{ modalCompanyDetails.content.phone }}</td>
            </tr>
            <tr>
              <th>Adresse Email</th>
              <td>{{ modalCompanyDetails.content.email }}</td>
            </tr>
            <tr>
              <th>Adresse Physique</th>
              <td>{{ modalCompanyDetails.content.address }}</td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-end mt-2">
          <mdb-btn size="md" color="grey" @click="modalCompanyDetails.show = false"
            >Fermer</mdb-btn
          >
        </div>
      </mdb-modal-body>
    </mdb-modal>
  </div>
</template>

<script>
import TecPagination from "@/tba/components/Pagination";
import {
  mdbCard,
  mdbCardBody,
  mdbView,
  mdbBtn,
  mdbSwitch,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbRow,
  mdbCol,
  mdbSelect,
  mdbInput,
  mdbModal,
  mdbModalTitle,
  mdbModalBody,
} from "mdbvue";
import RegimeOption from "@/utils/Options/RegimeOption";
import ranString from "randomstring";
export default {
  metaInfo: {
    title: "TECBILL APP",
    titleTemplate: "%s - ENTREPRISES",
    htmlAttrs: {
      lang: "fr",
      amp: true,
    },
  },
  components: {
    mdbCard,
    mdbCardBody,
    mdbView,
    mdbBtn,
    mdbSwitch,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbRow,
    mdbCol,
    mdbSelect,
    mdbInput,
    mdbModal,
    mdbModalTitle,
    mdbModalBody,
    TecPagination,
  },

  data() {
    return {
      dataPagination: {
        pagination: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [
            { text: "5", value: "5", selected: true },
            { text: "10", value: "10" },
            { text: "25", value: "25" },
            { text: "50", value: "50" },
            { text: "100", value: "100" },
          ],
          total: 0,
        },
        searchQuery: "",
        propsToSearch: ["ifu", "rccm", "name", "email", "phone", "address"],
      },

      empty: false,
      loading: false,

      data: {
        columns: [
          {
            label: "Raison Sociale",
            field: "name",
            sort: true,
          },
          {
            label: "N° IFU",
            field: "ifu",
            sort: true,
          },
          {
            label: "RCCM",
            field: "rccm",
            sort: true,
          },
          {
            label: "Adresse Email",
            field: "email",
            sort: true,
          },
          {
            label: "Telephone",
            field: "phone",
            sort: true,
          },

          {
            label: "Adresse Physique",
            field: "address",
            sort: true,
          },
          {
            label: "",
            field: "action",
            sort: true,
          },
        ],
        rows: [],
      },

      form: {
        id: "",
        name: "",
        ifu: "",
        rccm: "",
        phone: "",
        email: "",
        address: "",

        user: {
          firstname: "",
          lastname: "",
          phone: "",
          email: "",
          password: "",
          password_c: "",
          password_default: "",
          is_active: true,
          is_archived: false,
        },

        setting: {
          id: "",
          regime_fiscal: "reel",
          nim: "",
          signatory: "",
          signatory_title: "",
          digital_signature: "",
          is_automatic: false,
          bank: "",
          iban: "",
          swift: "",
          bank_address: "",
          bank_is_active: false,
        },
      },

      regimes: RegimeOption,
      regimeOption: RegimeOption,

      modalCompanyDetails: {
        show: false,
        edit: false,
        title: "Détails de l'entreprise",
        content: {},
      },
      modalCompany: {
        show: false,
        edit: false,
        title: "Ajouter une entreprise",
        content: {},
        btn: false,
      },
    };
  },

  methods: {
    //Fonction de fonctionnement
    addOrEdit(data = null) {
      if (data instanceof Object) {
        this.modalCompany.title = "Modifier une entreprise";
        this.modalCompany.content = data;
        this.modalCompany.edit = true;
        this.formRest(data);
      } else {
        this.modalCompany.title = "Ajouter une entreprise";
        this.modalCompany.content = {};
        this.modalCompany.edit = false;
        this.cleanForm("company");
      }

      this.modalCompany.show = true;
    },

    getRegime(value) {
      this.form.setting.regime_fiscal = value;
    },

    setRegimfisc() {
      this.regimeOption = [];

      this.regimes.forEach((rf) => {
        this.regimeOption.push({
          text: rf.text,
          value: rf.value,
          selected: rf.selected,
        });
      });

      this.regimeOption;
    },

    cleanForm(type) {
      if (type === "company") {
        this.form.id = "";
        this.form.name = "";
        this.form.ifu = "";
        this.form.rccm = "";
        this.form.phone = "";
        this.form.email = "";
        this.form.address = "";

        this.form.user.id = "";
        this.form.user.firstname = "";
        this.form.user.lastname = "";
        this.form.user.phone = "";
        this.form.user.email = "";
        this.form.user.password = "";
        this.form.user.password_default = "";
        this.form.user.password_c = "";
        this.form.user.is_active = true;
        this.form.user.is_archived = false;

        this.form.setting.id = "";
        this.form.setting.regime_fiscal = "reel";
        this.form.setting.nim = "";
        this.form.setting.signatory = "";
        this.form.setting.signatory_title = "";
        this.form.setting.digital_signature = "";
        this.form.setting.is_automatic = false;
        this.form.setting.bank = "";
        this.form.setting.iban = "";
        this.form.setting.swift = "";
        this.form.setting.bank_address = "";
        this.form.setting.bank_is_active = true;
      }
    },

    formRest(data) {
      this.form.id = data.id;
      this.form.name = data.name;
      this.form.ifu = data.ifu;
      this.form.rccm = data.rccm;
      this.form.phone = data.phone;
      this.form.email = data.email;
      this.form.address = data.address;
    },

    details(row) {
      this.modalCompanyDetails.content = row;

      this.modalCompanyDetails.title = `${row.name}`;

      this.modalCompanyDetails.show = true;
      //
    },

    async loadCompanies() {
      this.$nprogress.start();
      this.loading = !this.loading;
      await this.$store
        .dispatch("company/findAll")
        .then((response) => {
          this.$nprogress.done();
          this.loading = !this.loading;
          if (
            response.data.companies.filter((row) => row.type !== "super").length === 0
          ) {
            this.empty = true;
          } else {
            this.empty = false;
          }

          this.data.rows = response.data.companies.filter(
            (element) => element.type !== "super"
          );
        })
        .catch((error) => {
          this.$nprogress.done();
          this.loading = !this.loading;
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async save() {
      this.$store.commit("company/SET_CLEAN");
      this.modalCompany.btn = !this.modalCompany.btn;

      let pass = ranString.generate(8);
      this.form.user.password = pass;
      this.form.user.password_c = pass;
      this.form.user.password_default = pass;
      await this.$store
        .dispatch("company/save", this.form)
        .then((response) => {
          this.modalCompany.show = false;
          this.modalCompany.btn = !this.modalCompany.btn;
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.cleanForm("company");
          this.loadCompanies();
        })
        .catch((error) => {
          this.modalCompany.btn = !this.modalCompany.btn;
          if (error.response.data.errors) {
            this.$store.commit("company/SET_NAME", error.response.data.errors.name);
            this.$store.commit("company/SET_IFU", error.response.data.errors.ifu);
            this.$store.commit("company/SET_RCCM", error.response.data.errors.rccm);
          }
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async update() {
      this.$store.commit("company/SET_CLEAN");
      this.modalCompany.btn = !this.modalCompany.btn;
      await this.$store
        .dispatch("company/update", this.form)
        .then((response) => {
          this.modalCompany.show = false;
          this.modalCompany.btn = !this.modalCompany.btn;
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.cleanForm("company");
          this.loadCompanies();
        })
        .catch((error) => {
          this.modalCompany.btn = !this.modalCompany.btn;
          if (error.response.data.errors) {
            this.$store.commit("company/SET_NAME", error.response.data.errors.name);
            this.$store.commit("company/SET_IFU", error.response.data.errors.ifu);
            this.$store.commit("company/SET_RCCM", error.response.data.errors.rccm);
          }
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async deleteCompany(id) {
      let res = await this.$store.dispatch("swal/doYouDelete");
      if (res.isDismissed) {
        return;
      }
      await this.$store
        .dispatch("company/delete", id)
        .then((response) => {
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.loadCompanies();
        })
        .catch((error) => {
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    // Systeme de datatable
    totalCalcul() {
      return (this.dataPagination.pagination.total = this.data.rows.length);
    },

    searchQ() {
      if (!this.dataPagination.searchQuery) {
        // return this.pagedData;
        return new Error("Nothing To Search");
      }

      let result = this.data.rows.filter((row) => {
        let isIncluded = false;
        for (let key of this.dataPagination.propsToSearch) {
          let rowValue = "";
          rowValue = row[key].toString().toLowerCase();
          if (
            rowValue.includes &&
            rowValue.includes(this.dataPagination.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      this.dataPagination.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },

    nbrPerPageSelected(value) {
      let nbr = value;

      return this.nbrPerPage(nbr);
    },

    nbrPerPage(nbr) {
      return (this.dataPagination.pagination.perPage = nbr);
    },
  },

  created() {
    this.loadCompanies();
    this.setRegimfisc();
  },

  computed: {
    pageData() {
      return this.data.rows.slice(this.from, this.to);
    },

    to() {
      let highBound = this.from + this.dataPagination.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    queriedData() {
      let result = this.searchQ();
      return result instanceof Error ? this.pageData : result;
    },

    from() {
      return (
        this.dataPagination.pagination.perPage *
        (this.dataPagination.pagination.currentPage - 1)
      );
    },

    total() {
      return this.totalCalcul();
    },
  },
};
</script>
