<script>
export default [
    {
        text:"Réel",
        value:"reel",
        selected:false
    },
    {
        text:"TPS",
        value:"tps",
        selected:false
    },
]
</script>